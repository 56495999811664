<template>
  <div>
    <div class="pb-2">
      <h3>Product Class Management</h3>
      <hr />
    </div>
    <div class="row">
      <div class="col-12">
        <button
          class="btn btn-primary float-left mb-3"
          type="button"
          @click="addClass"
        >
          ADD NEW PRODUCT CLASS
          <i class="fa fa-plus-circle" />
        </button>
      </div>
    </div>
    <div class="card">
      <div class="card-header">
        <div class="card-title mb-0">Product Classes</div>
      </div>
      <div class="card-body">
        <div class="d-flex mb-2">
          <div class="align-self-center">Items Per Page:</div>
          <div class="ml-2">
            <b-form-select
              v-model="perPage"
              id="perPageSelect"
              size="sm"
              :options="pageOptions"
              aria-label="Items Per Page"
            ></b-form-select>
          </div>
        </div>
        <b-table
          ref="table"
          striped
          :fields="fields"
          :items="products"
          stacked="xl"
          small
          class="mt-4"
          :current-page="currentPage"
          :per-page="perPage"
          bordered
          sort-icon-left
        >
          <template v-slot:cell(id)="data">
            <button
              class="btn btn-primary btn-sm m-1"
              @click="viewClass(data.item.id)"
            >
              View
            </button>
            <button
              class="btn btn-primary btn-sm m-1"
              @click="editClass(data.item.id)"
            >
              Modify
            </button>
          </template>
          <template v-slot:cell(productClassInternalDescription)="data">
            <div v-if="data.item.productClassInternalDescription">
              {{ data.item.productClassInternalDescription.substr(0, 50) }}
            </div>

            <b-collapse
              :id="'productClassInternalDescription' + data.item.id"
              v-if="
                data.item.productClassInternalDescription &&
                  data.item.productClassInternalDescription.length >= 50
              "
            >
              {{
                data.item.productClassInternalDescription.substring(
                  50,
                  data.item.productClassInternalDescription.length - 1
                )
              }}
              <br />
            </b-collapse>
            <b-button
              v-if="
                data.item.productClassInternalDescription &&
                  data.item.productClassInternalDescription.length >= 50
              "
              v-b-toggle="'productClassInternalDescription' + data.item.id"
              variant="link"
              ><span class="when-open text-primary">Show less</span
              ><span class="when-closed text-primary">Show more...</span>
            </b-button>
          </template>
        </b-table>
        <div class="d-flex">
          <div class="mr-auto">
            Showing
            {{ perPage * currentPage - perPage + (totalRows > 0 ? 1 : 0) }} to
            {{ perPageDisplay }} of {{ totalRows }} entries
          </div>
          <div>
            <b-pagination
              v-model="currentPage"
              :total-rows="totalRows"
              :per-page="perPage"
              align="fill"
              size="sm"
              class="my-0"
            ></b-pagination>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import AdminProductManagementService from "@/services/admin/AdminProductManagementService.js";

export default {
  name: "AdminProductClassList",
  title: "Admin - Product Class Management",
  data() {
    return {
      products: [],
      fields: [
        {
          key: "referenceId",
          sortable: true,
          label: "Reference ID"
        },
        {
          key: "productClassName",
          sortable: true,
          label: "Product Class Name"
        },
        { key: "productClassDescription", label: "Product Class Description" },
        {
          key: "productClassInternalDescription",
          label: "Product Class Internal Description"
        },
        { key: "id", label: "Actions/Options" }
      ],
      currentPage: 1,
      totalRows: 25,
      perPage: 25,
      pageOptions: [10, 25, 50, 100]
    };
  },
  computed: {
    perPageDisplay() {
      if (this.perPage * this.currentPage > this.totalRows) {
        return this.totalRows;
      }
      if (this.totalRows > this.perPage) {
        return this.perPage;
      }
      return this.totalRows;
    },
    tenantId() {
      return this.$store.getters["tenant/tenantId"];
    }
  },
  methods: {
    editClass(id) {
      this.$router.push("/admin/product-class-modify/" + id).catch(() => {});
    },
    viewClass(id) {
      this.$router.push("/admin/product-class-view/" + id).catch(() => {});
    },
    addClass() {
      this.$router.push("/admin/product-class-add").catch(() => {});
    },
    getProducts() {
      const productManagementService = new AdminProductManagementService(
        this.tenantId
      );
      productManagementService.getAllStreamlinedProducts().then(response => {
        this.products = response
          .map(x => {
            return {
              ...x,
              referenceId: `P${(x.productClassId + "").padStart(3, "0")}`
            };
          })
          .sort((a, b) =>
            a.productClassName > b.productClassName
              ? 1
              : b.productClassName > a.productClassName
              ? -1
              : 0
          );
        this.totalRows = response.length;
      });
    }
  },
  created() {
    this.getProducts();
  }
};
</script>
<style scoped>
.collapsed > .when-open,
.not-collapsed > .when-closed {
  display: none;
}
</style>
